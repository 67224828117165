import React, {useContext, useMemo, useState} from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import DataContext from "../../../context/DataContext"
import Typography from "../../reusable/Typography/Typography"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import PlusDash from "../../reusable/PlusDash"
import {getMenuTitles, MenuTitlesItemType} from "../../../utility/Helper"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import {useRouter} from "next/router"
import {premiumBrands} from "../../../constants"

const FooterContentMobile = () => {
	const {t} = useTranslation()
	const [togglePages, setTogglePages] = useState(false)
	const [toggleBrands, setToggleBrands] = useState(false)
	const [toggleCategories, setToggleCategories] = useState(false)

	const {collections, brands} = useContext(DataContext)
	const menuTitles = useMemo(() => getMenuTitles(collections, brands), [collections, brands])
	const router = useRouter()
	return (
		<section className="col-12 col-lg-7">
			<section className="row pb-5">
				<article className="col-12"
					style={{borderBottom: "1px solid #828486"}}>
					<div onClick={() => setTogglePages(!togglePages)}
						className={"footer-item   pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3"
									semanticTag="h3"
									style={{fontSize: 14}}>{t("Pages")}</Typography>
								<AccordionAnimation inProp={togglePages}
									duration={500}
									transTimingFn="ease-out">
									<ul className="list-unstyled  mt-3">
										<li className="footer-item">
											<Link href={"/blog"}>{t("Blog")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/ueber-uns"}>{t("Über uns")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/terms-conditions"}>{t("mybikeplan.ch AGBs")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"https://www.swissbilling.ch/wp-content/uploads/swb-agb.pdf"}>{t("Swissbilling")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/agb/AGB_POSP00016512_2024-10-23.pdf"}>{t("MFGroup")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/kundendienst"}>{t("Kundendienst")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/datenschutz"}>{t("Datenschutz")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/referral"}>{t("Referral")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"/bike-verzeichnis"}>{t("E-Bike Wiki")}</Link>
										</li>
										<li className="footer-item">
											<Link href={"https://join.com/companies/mybikeplan"}>{t("careers")}</Link>
										</li>
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end"
								style={{width: 20, height: 20}}>
								<PlusDash iconColor="white"
									isOpen={togglePages}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12"
					style={{borderBottom: "1px solid #828486"}}>
					<div onClick={() => setToggleBrands(!toggleBrands)}
						className={"footer-item  pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3"
									semanticTag="h3"
									style={{fontSize: 14}}>{t("Brands")}</Typography>
								<AccordionAnimation inProp={toggleBrands}
									duration={500}
									transTimingFn="ease-out">
									<ul className="list-unstyled mt-3">
										{menuTitles.mobile[1].items?.map(item => {
											const isPremiumBrand = premiumBrands.includes(item?.href?.slice(1))

											return (<li key={item.name}
												className="footer-item">
												{item?.type === MenuTitlesItemType.category ?
													<Link href={filterUrl({[urlParams.categories]: item.href?.slice(1)}, urlParams.categories, item.href?.slice(1))} >
														<a suppressHydrationWarning
															className="footer-link rounded">
															{item.name}
														</a>
													</Link> :
													<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${item.href?.slice(1)}` : ` /fr/brand-world/${item.href?.slice(1)}` : filterUrl({[urlParams.brand]: item.href?.slice(1)}, urlParams.brand, item.href?.slice(1))}>
														<a suppressHydrationWarning
															className="footer-link rounded">
															{item.name}
														</a>
													</Link>}
											</li>)
										})}
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end"
								style={{width: 20, height: 20}}>
								<PlusDash iconColor="white"
									isOpen={toggleBrands}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12">
					<div onClick={() => setToggleCategories(!toggleCategories)}
						className={"footer-item pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3"
									semanticTag="h3"
									style={{fontSize: 14}}>{t("Kategorien")}</Typography>
								<AccordionAnimation inProp={toggleCategories}
									duration={500}
									transTimingFn="ease-out">
									<ul className="list-unstyled mt-3">
										{menuTitles.mobile[0].items?.map(item => {
											const isPremiumBrand = premiumBrands.includes(item?.href?.slice(1))

											return (<li key={item.name}
												className="footer-item">
												{item.name === "Zubehör" || item.name === "Accessoires" ? <Link href={item.href} >{item.name}</Link> :
													item?.type === MenuTitlesItemType.category ?
														<Link href={filterUrl({[urlParams.categories]: item.href?.slice(1)}, urlParams.categories, item.href?.slice(1))} >
															<a suppressHydrationWarning
																className="footer-link rounded">
																{item.name}
															</a>
														</Link> :
														<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${item.href?.slice(1)}` : ` /fr/brand-world/${item.href?.slice(1)}` : filterUrl({[urlParams.brand]: item.href?.slice(1)}, urlParams.brand, item.href?.slice(1))}>
															<a suppressHydrationWarning
																className="footer-link rounded">
																{item.name}
															</a>
														</Link>}
											</li>)
										}
										)}
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end"
								style={{width: 20, height: 20}}>
								<PlusDash iconColor="white"
									isOpen={toggleCategories}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12 mt-2">
					<ul className="list-unstyled mb-0">
						<li className="footer-item">
							<Typography variant={"bodyLgBlack"}
								style={{fontSize: 20, marginTop: 54}}>{t("Mybikeplan AG")}</Typography>
						</li>
						<li className="footer-item"
							style={{marginTop: 26}}>
							<Link href={"tel:+41%2043%20505%2013%2018"}>{t("Sales: 043 505 13 18")}</Link>
						</li>
						<li className="footer-item"
							style={{marginTop: 20}}>
							<Link href={"mailto:sales@mybikeplan.ch"}>sales@mybikeplan.ch</Link>
						</li>
						<br/><br/>
						<li className="footer-item">
							<Typography variant={"bodyLgBlack"}
								className="mb-3"
								style={{fontSize: 20}}>Follow us on</Typography>
							<div className="row g-3">
								<div className="col-auto">
									<Link href={"https://www.instagram.com/mybikeplan.ch/"}>
										<a target="_blank"
											className="text-md text-white mt-5">
											<Image src={"/assets/icons/ig-white-icon.svg"}
												width={24}
												height={24}/>
										</a>
									</Link>
								</div>
								<div className="col-auto ms-2">
									<Link href={"https://www.facebook.com/mybikeplan"}>
										<a target="_blank"
											className="text-md text-white mt-5">
											<Image src={"/assets/icons/fb-white-icon.svg"}
												width={24}
												height={24}/>
										</a>
									</Link>
								</div>
							</div>
						</li>
					</ul>
				</article>
			</section>
		</section>
	)
}

export default FooterContentMobile
