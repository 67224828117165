import React, {useContext} from "react"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../context/LayoutContext"
import {useRouter} from "next/router"

type Props = {
  isUspWhite?: boolean;
  isBrandWorld?: boolean;
  isNewUsp?: boolean;
};

const UspSection: React.FC<Props> = ({isUspWhite, isBrandWorld = false, isNewUsp}) => {
	const {t} = useTranslation("common")
	const router = useRouter()
	const uspItems = [
		{
			icon: "/assets/icons/howItWorks/bike-icon-left.png",
			whiteIcon: "/assets/icons/usp-delivery-white.svg",
			text: `${t("showroom-address-label")}`,
			link: t("showroom-brand-world-link")
		},
		{
			icon: "/assets/icons/usp-delivery.svg",
			whiteIcon: "/assets/icons/usp-delivery-white.svg",
			text: `${t("top-header-text-1-2")} ${t("top-header-text-1-3")}`
		},
		{
			icon: "/assets/icons/usp-return.svg",
			whiteIcon: "/assets/icons/usp-return-white.svg",
			text: `${t("top-header-text-4-1")} ${t("top-header-text-4-2")}`
		},
		{
			icon: "/assets/icons/shield.svg",
			whiteIcon: "/assets/icons/usp-guarantee-white.svg",
			text: `${t("2 Jahre")} ${t("Herstellergarantie")}`
		},
		{
			icon: "/assets/icons/usp-service.svg",
			whiteIcon: "/assets/icons/usp-service-white.svg",
			text: `${t("top-header-text-3-1")} ${t("top-header-text-3-2")} ${t("top-header-text-3-3")}`
		},
		{
			icon: "/assets/icons/checkmark-emoji.svg",
			whiteIcon: "/assets/icons/usp-service-white.svg",
			text: `${t("best-monthly-price-label")}`
		}
	]

	if (isNewUsp) {
		const first = uspItems.slice(0, 2)
		const second = uspItems.slice(-2)
		return (<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto", marginTop: "24px"}}
			className="d-flex align-items-center  hide-overflow-scrollbar flex-md-wrap justify-content-start flex-column flex-md-row">
			<div className="d-md-none d-flex ms-3 align-items-center justify-content-start gap-2 ">
				{first.map((item, index) => <div key={index}
					style={{
						gap: "8px", borderRadius: "24px", padding: "8px 16px",
						background: index === 0 ? "#FFE9C2" : "#f8f8f8", flexShrink: "0"
					}}
					onClick={() => {
						if (!item?.link) {
							return
						}

						router.push(item?.link)
					}}
					className="d-flex align-items-center">
					<Image src={isUspWhite ? item.whiteIcon : item.icon}
						width={24}
						layout="fixed"
						height={24} />
					<Typography
						className="whitespace-nowrap"
						variant={"bodyLg"}
						semanticTag={"span"}>
						{item.text}
					</Typography>
				</div>)}
			</div>

			<div className="d-md-none ms-3 d-flex align-items-center justify-content-start gap-2">
				{second.reverse().map((item, index) => <div key={index}
					style={{
						gap: "8px", borderRadius: "24px", padding: "8px 16px",
						background: "#f8f8f8", flexShrink: "0"
					}}
					className="d-flex align-items-center">
					<Image src={isUspWhite ? item.whiteIcon : item.icon}
						width={24}
						layout="fixed"
						height={24} />
					<Typography
						className="whitespace-nowrap"
						variant={"bodyLg"}
						semanticTag={"span"}>
						{item.text}
					</Typography>
				</div>)}
			</div>
			{uspItems.map((item, index) => <div key={index}
				style={{
					gap: "8px", borderRadius: "24px", padding: "8px 16px",
					background: "#f8f8f8", flexShrink: "0"
				}}
				onClick={() => {
					if (!item?.link) {
						return
					}

					router.push(item?.link)
				}}
				className={`d-md-flex d-none align-items-center ${item?.link === t("showroom-brand-world-link") ? "usp-is-link" : ""}`}>
				<Image src={isUspWhite ? item.whiteIcon : item.icon}
					width={24}
					layout="fixed"
					height={24} />
				<Typography
					className="whitespace-nowrap"
					variant={"bodySm"}
					semanticTag={"span"}>
					{item.text}
				</Typography>
			</div>
			)}

		</div>)
	}

	const {isMobile} = useContext(LayoutContext)
	return (
		<>
			<div style={{flexWrap: "wrap", gap: "16px"}}
				className={`${isBrandWorld ? "d-flex align-items-center my-5 justify-content-evenly container " : "d-none d-md-flex  justify-content-between"}  ${isUspWhite ? "text-white pb-3" : ""}`}>
				{uspItems.slice(0, uspItems.length - 1).map((item, index) => (
					<div key={index}
						style={{gap: "8px", width: isBrandWorld && isMobile ? "163px" : "auto"}}
						className="col-auto d-flex flex-column align-items-center">
						<Image src={isUspWhite ? item.whiteIcon : item.icon}
							width={48}
							height={48} />
						<Typography
							style={{textAlign: "center"}}
							variant={"bodyLg"}
							semanticTag={"span"}>
							{item.text}
						</Typography>
					</div>
				))}
			</div>
			{isBrandWorld ? null : <div className={`d-md-none row justify-content-between ${isUspWhite ? "text-white" : ""}`}>
				{uspItems.map((item, index) => (
					<div key={index}
						className="col-12 d-flex align-items-center mb-3"
						style={{columnGap: 16}}>
						<Image src={isUspWhite ? item.whiteIcon : item.icon}
							width={24}
							height={24} />
						<Typography variant={"bodyLg"}
							semanticTag={"span"}>
							{item.text}
						</Typography>
					</div>
				))}
			</div>}
		</>

	)
}

export default UspSection
